import React from "react";

export default {
  Header: {
    title: "随时随地享受干净清澈健康的水",
    title: (
      <>
        <h2>随时随地</h2>
        <h2>享受干净清澈健康的水</h2>
      </>
    ),
    paragraph: "Clean Clear Sanitized Pool Water-Anywhere",
  },
  About: {
    paragraph:
      "清水清®HTH®拥有一支由100名研发人员组成的综合研发团队(分布在美国、巴西、南非和欧洲),同时在美国佐治亚州阿尔法利塔市(Alpharetta)的拥有一个6,000平米的研发中心,在法国法尔图市Amboise拥有一个综合实验室。",
    Why: [
      "hth ® 次氯酸钙享誉全球",
      "紧凑型设备适配多种场景",
      "优秀的创新科技",
      "精选进口品质",
    ],
    Why2: [
      "泳池水维护产品全面覆盖",
      "专业支持团队",
      "绿色产品，健康无害",
      "全球源头工厂",
    ],
  },
  Gallery: [
    {
      title: "超浓缩澄清剂",
      largeImage: "img/portfolio/01-large.jpg",
      smallImage: "img/portfolio/01-small.jpg",
    },
    {
      title: "余氯/PH测试盒",
      largeImage: "img/portfolio/02-large.jpg",
      smallImage: "img/portfolio/02-small.jpg",
    },
    {
      title: "SHOCK冲击性处理剂",
      largeImage: "img/portfolio/03-large.jpg",
      smallImage: "img/portfolio/03-small.jpg",
    },
    {
      title: "保湿澄清剂",
      largeImage: "img/portfolio/04-large.jpg",
      smallImage: "img/portfolio/04-small.jpg",
    },
    {
      title: "3合1澄清阻垢杀藻剂",
      largeImage: "img/portfolio/05-large.jpg",
      smallImage: "img/portfolio/05-small.jpg",
    },
    {
      title: "超浓缩杀藻剂",
      largeImage: "img/portfolio/06-large.jpg",
      smallImage: "img/portfolio/06-small.jpg",
    },
    {
      title: "PH升高剂",
      largeImage: "img/portfolio/07-large.jpg",
      smallImage: "img/portfolio/07-small.jpg",
    },
    {
      title: "无溴无氯冲击性处理剂",
      largeImage: "img/portfolio/08-large.jpg",
      smallImage: "img/portfolio/08-small.jpg",
    },
    {
      title: "6合1室外池消毒饼剂",
      largeImage: "img/portfolio/09-large.jpg",
      smallImage: "img/portfolio/09-small.jpg",
    },
  ],
  Services: [
    {
      icon: "fa fa-wordpress",
      name: "Step1：水质检测",
      text: "在启用您的泳池前，您需要通过专业检测设备，对您的泳池水质进行科学的了解，从而帮助您判断是否需要专业的水处理或直接启用；",
    },
    {
      icon: "fa fa-cart-arrow-down",
      name: "Step2：PH调节",
      text: "水的酸碱度过高或者过低都会对人体与水质处理产生不良的影响，我们需要利用PH升高剂或者PH降低剂来维持水质的PH值在7-7.8（常规情况下7.4最佳）之间",
    },
    {
      icon: "fa fa-cloud-download",
      name: "Step3：水质消毒",
      text: "当泳池壁打滑或者水质浑浊不清，大概率是细菌滋生的结果，需要对水质进行消毒处理，确保水质对人体无害",
    },
    {
      icon: "fa fa-language",
      name: "Step4：水质澄清",
      text: "被清理的微生物残留有时候会小到砂缸无法处理，需要利用澄清剂对微生物残留进行聚集处理，以便最终被砂缸过滤，还水质澄清",
    },
    {
      icon: "fa fa-plane",
      name: "额外步骤：绿藻处理",
      text: "当阳光充足的情况下，水里会变成藻类生长的温床，同时伴随水质发绿，需要配合杀藻剂及时进行杀藻处理",
    },
    {
      icon: "fa fa-pie-chart",
      name: "额外步骤：泳池维护",
      text: "水质处理从来都不是发现问题，再解决问题的单选题，我们建议您在水质问题发生前，就采取对应的防护手段进行预防，确保水质时刻处于安全范围，不会危害人体健康",
    },
  ],
  Testimonials: [
    {
      img: "img/testimonials/01.gif",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/02.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
    {
      img: "img/testimonials/03.jpg",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
    },
    {
      img: "img/testimonials/07.png",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
    {
      img: "img/testimonials/05.png",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "John Doe",
      style: { width: "100px" },
    },
    {
      img: "img/testimonials/06.png",
      text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."',
      name: "Johnathan Doe",
    },
  ],
  Team: [
    {
      img: "img/team/01.jpg",
      name: "宠物泳池",
      job: "Director",
    },
    {
      img: "img/team/02.jpg",
      name: "私家别墅泳池",
      job: "Senior Designer",
    },
    {
      img: "img/team/03.jpg",
      name: "温泉水疗SPA池",
      job: "Senior Designer",
    },
    {
      img: "img/team/04.jpg",
      name: "亲子泳池",
      job: "Project Manager",
    },
  ],
  Contact: {
    address: "浙江省杭州市萧山区北干街道金城路165号金瑞大厦1幢901室",
    phone: "0571-58119117",
    email: "tad_xu@163.com",
    facebook: "fb.com",
    twitter: "twitter.com",
    youtube: "youtube.com",
  },
  Features: [
    {
      // "icon": "fa fa-comments-o",
      icon: "history1.jpg",
      title: "美国品牌",
      text: (
        <div>
          <p>清水清HTH始于1928年</p>
          <p>全世界第一家次氯酸钙生产厂家</p>
        </div>
      ),
    },
    {
      // "icon": "fa fa-bullhorn",
      icon: "history2.jpg",
      title: "创新科技",
      text: (
        <div>
          <p>针对游泳池和SPA水疗池水</p>
          <p>每年研究发布数10个水处理产品</p>
        </div>
      ),
    },
    {
      // "icon": "fa fa-group",
      icon: "history3.jpg",
      title: "高标准生产工厂",
      text: (
        <div>
          <p>全球六大生产研发基地</p>
          <p>1200名全职员工</p>
        </div>
      ),
    },
    {
      // "icon": "fa fa-magic",
      icon: "history4.png",
      title: "专注水处理",
      text: (
        <div>
          <p>匠心工艺，专精泳池水处理</p>
          <p>严格质检，经过多轮严苛测试</p>
        </div>
      ),
    },
  ],
};
